<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <!-- <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="8" md="8">
        <h1>Motivos de cancelación</h1>
      </v-col>

      <v-col cols="12" sm="4" md="4">
        <v-row justify="end">
          <v-btn
            color="primary"
            @click="
              selectedItem = null;
              addDialog = true;
            "
            class="ma-2 white--text"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Nuevo
          </v-btn>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="cancellationReasons"
          :search="$store.state.search"
          :items-per-page="15"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              :loading="loading"
              @change="
                switchControlChanged({ active: item.active }, item['.key'])
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn :loading="loading" @click="editI(item)" small color="primary">Editar</v-btn>

            <v-btn
              @click="deleteI(item)"
              small
              :loading="loading"
              class="ml-3 white--text"
              color="red darken-4"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog persistent v-if="addDialog" v-model="addDialog" max-width="700px">
      <add
        @success="handleSuccess"
        @cancel="addDialog = false"
        :reason="selectedItem"
      />
    </v-dialog>

    <v-dialog
      persistent
      v-if="deleteDialog"
      v-model="deleteDialog"
      max-width="500px"
    >
      <delete
        @confirm="handleDelete"
        @cancel="deleteDialog = false"
        :item="selectedItem"
        :loading="loading"
      />
    </v-dialog>
  </v-container>
</template>
  
  <script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";

import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import { mapState } from "vuex";


import add from "./add";
import Delete from "../../../components/delete.vue";

export default {
  name: "cancellation-reasons",
  components: {
    lottie: Lottie,
    add,
    Delete,
  },
  mixins: [lottieSettings],

  data() {
    return {
      loading: true,
      snackbar: false,
      addDialog: false,
      snackbarText: "",
      cancellationReasons: [],
      selectedItem: null,
      deleteDialog: false,
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Descripción",
          value: "description",
        },
        {
          text: "Activo",
          value: "active",
        },
        {
          text: "Fecha Creación",
          value: "createdAt",
        },
        {
          text: "",
          value: "options",
          align: "end",
          width: "300px",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) {
        return "no disponible";
      }

      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("L");
    },
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    showExchangeDialog(item) {
      this.selectedItem = item;
      this.exchangeDialog = true;
    },

    handleDelete() {
      // this.selectedItem = item;
      // this.deleteDialog = true;
      this.loading = true;

      db.collection("cancellationReasons")
        .doc(this.selectedItem[".key"])
        .update({
          deleted: true,
          deletedBy: this.user[".key"],
          deletedAt: new Date(),
        })
        .then(() => {
          this.loading = false;
          this.snackbarText = "Información actualizada correctamente.";
          this.snackbar = true;
          this.deleteDialog = false;
        })
        .catch((err) => {
          this.loading = false;
          this.snackbarText = err.message;
          this.snackbar = true;
        });
    },

    editI(item) {
      this.selectedItem = item;
      this.addDialog = true;
    },

    deleteI(item) {
      this.selectedItem = item;
      this.deleteDialog = true;
    },

    switchControlChanged(data, itemId) {
      this.loading = true;

      db.collection("cancellationReasons")
        .doc(itemId)
        .update(data)
        .then(() => {
          this.loading = false;
          this.snackbarText = "Información actualizada correctamente.";
          this.snackbar = true;
        })
        .catch((err) => {
          this.loading = false;
          this.snackbarText = err.message;
          this.snackbar = true;
        });
    },
    handleSuccess() {
      this.snackbarText = "Información actualizada exitosamente.";
      this.snackbar = true;
      this.selectedItem = null;
      this.addDialog = false;
    },
  },
  async mounted() {
    this.$store.commit("setSearchTerm", "motivo de cancelación");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    await this.$binding(
      "cancellationReasons",
      db.collection("cancellationReasons").where("deleted", "==", false)
    );

    this.loading = false;
  },
};
</script>
  
  <style>
</style>
  